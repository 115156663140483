// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiHostName: 'measures.scienceofbehaviorchange.org',
  apiPort: '8003',
  client_id: '5zv4XtH6JBplesTqfj9B1gZsowpv6lupOz1wQrOl',
  client_secret: 'xClPhSUAmV914diFi2HbFpkOUirUQaKQ8VbThpQUdQQOqyd8IwdF09qoebRrgSUqsGlki6H2Qv8ZvdPZGupkThioV2PXTdFtLxThLDEvMnnYXY8HHl1tedIxB7QBjwqd',
  host_url: 'https://measures.scienceofbehaviorchange.org',
  GA_TRACKING_ID: 'UA-156373656-1',
};
// export const environment = {
//   production: true,
//   apiHostName: 'oip-dev.busara.io',
//   apiPort: '8003',
//   client_id: '5zv4XtH6JBplesTqfj9B1gZsowpv6lupOz1wQrOl',
//   client_secret: 'xClPhSUAmV914diFi2HbFpkOUirUQaKQ8VbThpQUdQQOqyd8IwdF09qoebRrgSUqsGlki6H2Qv8ZvdPZGupkThioV2PXTdFtLxThLDEvMnnYXY8HHl1tedIxB7QBjwqd',
//   host_url: 'https://oip-dev.busara.io',
//   GA_TRACKING_ID: '--UA-156373656-1--',
// };

// export const environment = {
//   production: false,
//   apiHostName: 'localhost',
//   apiPort: '8003',
//   client_id: '5zv4XtH6JBplesTqfj9B1gZsowpv6lupOz1wQrOl',
//   client_secret: 'xClPhSUAmV914diFi2HbFpkOUirUQaKQ8VbThpQUdQQOqyd8IwdF09qoebRrgSUqsGlki6H2Qv8ZvdPZGupkThioV2PXTdFtLxThLDEvMnnYXY8HHl1tedIxB7QBjwqd',
//   host_url: 'http://localhost:8010',
//   GA_TRACKING_ID: '__LOCAL_UA-155988149-1__LOCAL',
// };
/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
